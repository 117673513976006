import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
import { useGlobalContext } from '../../appContext';
import Banner from '../../components/Banner/Banner';
import { TRAININGS } from '../../appConstants';
import LoadingState from '../../components/LoadingState/LoadingState';
import useContentful from '../../hooks/useContenful';

const Training = () => {
	const { trainingBody, trainingTitle, isLoading } =
		useGlobalContext();

		const [trainings, setTrainings] = useState(null)

		const { getCmsResponse } = useContentful();

		useEffect(() => {
			(async () => {
				try {
					const array = await getCmsResponse(TRAININGS)
					setTrainings(array);
				} catch (error) {
					console.error('Failed to load the programs data', error);
				}
			})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);


	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<div className="training">
			<Banner title={trainingTitle} info={trainingBody} />

			{trainings && trainings.map((training, id) => {
				const { title, description, leadToUrl, url } = training;
				return (
					<div key={id} className="training-border">
						<div className="training-wrapper wrapper">
							<div key={id} className="training-container">
								<h2 className="training-title">{title}</h2>
								<div className="regular-16 training-description">
									{documentToReactComponents(description, options)}
								</div>
								<p className="bold-16 training-link">
									{leadToUrl}&nbsp;
									<a
										className="training-anchor"
										href={url}
										target="_blank"
										rel="noreferrer">
										{url && url.slice(8)}
									</a>
								</p>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Training;
