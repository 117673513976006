import React, { useEffect, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import { useGlobalContext } from '../../appContext';
import ProjectCard from './ProjectCard';
import { PROJECTS } from '../../appConstants';
import LoadingState from '../../components/LoadingState/LoadingState';
import useContentful from '../../hooks/useContenful';

const Projects = () => {
    const { projectsBody, projectsTitle, isLoading } =
        useGlobalContext();
        const [projects, setProjects] = useState(null)

		const { getCmsResponse } = useContentful();

        useEffect(() => {
			(async () => {
				try {
					const array = await getCmsResponse(PROJECTS)
					setProjects(array);
				} catch (error) {
					console.error('Failed to load the projects data', error);
				}
			})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);


    if (isLoading) {
        return <LoadingState />;
    }


    return (
        <main className='projects-main'>
            {/* banner */}
            <Banner title={projectsTitle} info={projectsBody} />

            {/* rendering project cards */}
            <article className='projects-list-container'>
                <article className='projects-list wrapper'>
                    {projects && projects.map((project, id) => {
                        return (
                            <ProjectCard key={id} projectCardInfo={project} />
                        );
                    })}
                </article>
            </article>
        </main>
    );
};

export default Projects;
