import { Fragment } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import DefaultPlaceholderImg from '../../assets/default_dsl_placeholder.svg';



const ModalLayout = ({ children, open, setOpen, media }) => {
	return (
		<Transition as={Fragment} show={open}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<DialogPanel className="shadow-xl relative w-full xlg:w-1/2 max-w-[850px] overflow-hidden rounded-lg bg-neutral-100 text-left transition-all sm:my-8">
								<div className="flex justify-end items-start bg-contain bg-center border-b border-[#DAD7C9] bg-no-repeat p-2 h-[300px]" style={{ backgroundImage: `url(${media ? media : DefaultPlaceholderImg})` }}>
									<button onClick={() => setOpen(false)}>
										<XMarkIcon height={30} />
									</button>
								</div>
								{children}
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ModalLayout;
