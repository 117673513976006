import React from 'react';
import BannerIllustration from '../../assets/banner_illustration.png';


const BannerWithIllustration = () => {
	return (
		<div className="mt-[80px]">
			<div className="home-wrapper">
				<a
					href="/hackathon"
					className="bg-soft-black rounded-[10px] text-neutral-100 py-[72px] pl-[40px] flex flex-col items-start justify-start min-h-[300px]"
					style={{
						backgroundImage: `url(${BannerIllustration})`,
						backgroundSize: 'cover',
						backgroundPosition: 'left',
					}}>
					<div className="text-left">
						<h3 className="text-[16px] font-bold uppercase mb-[8px]">
							A Social Impact Hackathon
						</h3>
						<h1 className="text-4xl md:text-5xl font-bold uppercase">Digital Society</h1>
						<h1 className="text-4xl md:text-8xl font-bold mb-6 uppercase">Hack</h1>
						<div className="flex flex-col md:flex-row items-start justify-start gap-4 mb-[56px]">
							<span className="text-soft-black bg-neutral-100 font-semibold px-[12px] py-[8px] text-[18px] rounded-[4px] uppercase">
								January 25 - 31, 2025
							</span>
							<span className="text-soft-black bg-neutral-100 font-semibold px-[12px] py-[8px] text-[18px] rounded-[4px] uppercase">
								Hybrid @ McMaster Uni
							</span>
						</div>
						<h3
							href="#"
							className="inline-block uppercase text-neutral-100 font-bold text-[18px]">
							Register Now →
						</h3>
					</div>
				</a>
			</div>
		</div>
	);
};

export default BannerWithIllustration;
