import React, { useState } from 'react';
import { useGlobalContext } from '../../appContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
import defaultImg from '../../assets/defaultImg.jpg';

const LeftPane = ({ profileData }) => {
	const [readMore, setReadMore] = useState(true);
	const [showAll, setShowAll] = useState(false);
	const { tabletView, mobileView } = useGlobalContext();

	const { name, lastName, affiliateInformation, tags, img } =
		profileData?.[0] || {
			name: '',
			lastName: '',
			affiliateInformation: [],
			tags: [''],
			img: {},
		};
	const personImg = img?.fields?.file?.url || defaultImg;
	const bio = profileData[0]?.profile?.content || null;
	return (
		<div className="profile">
			<div className="header">
				<div className="person-image-container">
					<div className="person-image">
						<img src={personImg} alt={`${name} looking at the camera`} />
					</div>
				</div>
			</div>
			<div className="body">
				{/* main body */}
				<div className="body-content">
					<h1 className="name">
						{name} {lastName || ''}
					</h1>
					{affiliateInformation &&
						affiliateInformation.map((aff, id) => {
							return (
								<h2 className="semi-14" key={id}>
									{aff?.fields?.title},{' '}
									{aff?.fields?.department && `${aff?.fields?.department},`}{' '}
									{aff?.fields?.institution}
								</h2>
							);
						})}
				</div>
				{/* ------------------------------------------------------- */}
				{/* TAGS */}
				{/* make sure tags are not retrurning undefined */}
				{tags !== undefined ? (
					mobileView ? (
						// if its a mobile view, then check if length is > 5
						tags?.length > 5 ? (
							// if length > 5 then show a button
							<div>
								{showAll ? (
									<ul className="categories">
										{tags?.slice(0, 5).map((tag, id) => (
											<li key={id} className="semi-14 category tags">
												{tag}
											</li>
										))}
									</ul>
								) : (
									<ul className="categories">

										{tags?.slice(0).map((tag, id) => (
											<li key={id} className="semi-14 category tags">
												{tag}
											</li>
										))}
									</ul>
								)}
								<button
									className="read-btn show-btn"
									onClick={() => setShowAll(!showAll)}>
									<p className="regular-16">
										{showAll ? 'show all' : 'show less'}
									</p>

									{/* if button is true, then show all tags */}
								</button>
							</div>
						) : (
							// if length is not > 5, then render all
							<ul className="categories">
								{tags?.map((tag, id) => {
									return (
										<li key={id} className="semi-14 category tags">
											{/* {tag} */}
										</li>
									);
								})}
							</ul>
						)
					) : (
						<ul className="categories">
							{tags?.map((tag, id) => {
								return (
									<li key={id} className="semi-14 category tags">
										{tag}
									</li>
								);
							})}
						</ul>
					)
				) : null}
				{/* ---------------------------------------------- */}
				{/* BIO */}

				{/* if tabletView is true, then render only 1 paragraph with option to read-more
                    if tablet view is true and readmore is also true then render only 1 paragraph and to read more, other wise option to read less
                    if tabletView is false, then render all paragraphs with option  */}
				<div className="bio regular-16 paragraphs">
					{!tabletView &&
						documentToReactComponents(profileData[0]?.profile, options)}
					{tabletView &&
						!readMore &&
						documentToReactComponents(profileData[0]?.profile, options)}
					{tabletView && (
						<button
							className="read-btn mt-2"
							onClick={() => setReadMore(!readMore)}>
							<p className="regular-16">
								{readMore && bio?.length > 1
									? 'read more'
									: bio?.length === 1
									? ''
									: 'read less'}
							</p>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default LeftPane;
