import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
import { FaFacebookF, FaXTwitter } from 'react-icons/fa6';
import ProjectsCarousel from './ProjectsCarousel';
import { useGlobalContext } from '../../appContext';
import defaultImg from '../../assets/defaultImg.jpg';
import LoadingState from '../../components/LoadingState/LoadingState';
import Illustration from '../../assets/dsl_illustration.svg';
import BannerWithIllustration from '../../components/BannerWithIllustration';

function Home() {
	const handleSubmit = () => {
		window.open('https://buttondown.email/digitalsocietylab', 'popupwindow');
	};
	const { homepageData, isLoading } = useGlobalContext();

	if (!homepageData || homepageData.length === 0) {
		return null;
	}
	const homeStatic = homepageData[0];

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<div className="home">
			<BannerWithIllustration />
			{/*<div className="home-wrapper first">
				<div className="welcome-container">
					<h1 id="home">
						{homeStatic?.hometitle ?? 'Welcome to the Digital Society Lab'}
					</h1>
					<div className="regular-16">
						{homeStatic?.homebody &&
							documentToReactComponents(homeStatic.homebody, options)}
					</div>
				</div>
				<div>
				<img src={Illustration} alt="" className="illustration" />
				</div>
			</div>
			*/}

			<div className="home-wrapper">
				<div className="about-container mb-[100px]">
					<h1 id="home" className='mb-[28px]'>About the Digital Society Lab</h1>
					<div className="text-[20px]">
						{homeStatic?.homebody &&
							documentToReactComponents(homeStatic.homebody, options)}
					</div>
				</div>
				<div className="carousel-con">
					<ProjectsCarousel />
				</div>
				<div className="funding-container">
					<h2>
						{homeStatic?.homeFundersText ?? `Made possible with funding from:`}
					</h2>

					<div className="funding-logos">
						{homeStatic?.homeFunders?.map((funder) => (
							<div key={funder.sys.id} className="funder-logo">
								<a
									href={`https:${funder.fields.description}`}
									target="_blank"
									rel="noopener noreferrer">
									<img
										src={`https:${funder.fields.file.url}`}
										alt={funder.fields.title}
										style={{
											height:
												((funder.fields.title === 'Meta' ||
													funder.fields.title === 'SSHRC') &&
													'100px') ||
												(funder.fields.title === 'McMaster' && 'auto'),
										}}
									/>
								</a>
							</div>
						)) ?? (
							<div className="funder-logo">
								<img
									src={defaultImg}
									alt="a map indicating digital society lab location"
								/>
							</div>
						)}
					</div>
				</div>

				<div className="home-contact">
					<div className="home-address" style={{ display: 'flex' }}>
						<div style={{ width: '100%', 'align-self': 'center' }}>
							<div className="home-name">
								<div className="bold-18">Digital Society Lab</div>

								<div className="media-container">
									<a
										href="https://twitter.com/DigSocietyLab"
										target="_blank"
										rel="noopener noreferrer"
										aria-label="Follow us on X">
										<FaXTwitter aria-hidden="true" />
										<span className="sr-only">Follow us on X</span>
									</a>
									<a
										href="https://www.facebook.com/DigSocietyLab"
										target="_blank"
										rel="noopener noreferrer"
										aria-label="Follow us on Facebook">
										<FaFacebookF aria-hidden="true" />
										<span className="sr-only">Follow us on Facebook</span>
									</a>
								</div>
							</div>
							<div className="street-address bold-18">
								{homeStatic?.streetAddress &&
									documentToReactComponents(homeStatic.streetAddress, options)}
								<div class="paragraph">{homeStatic?.city}</div>
								<div class="paragraph">
									<span>{homeStatic?.province}</span>&nbsp;
									<span>{homeStatic?.postcode}</span>
								</div>
							</div>
							<div className="contact-methods bold-16">
								<div style={{ 'margin-bottom': '5px' }}>
									{homeStatic?.phoneNumber}
								</div>
								<div>
									<a href={`mailto: ${homeStatic.email}`}>
										{homeStatic?.email}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="stay-updated">
						<h2>
							{homeStatic?.subscribeFormTitle ||
								'Stay updated with the Digital Society Lab'}
						</h2>
						<div style={{ margin: '0.5rem 0' }}>
							{homeStatic?.subscribeFormSubtitle || ''}
						</div>
						<form
							action="https://buttondown.email/api/emails/embed-subscribe/digitalsocietylab"
							method="post"
							target="popupwindow"
							className="embeddable-buttondown-form"
							onSubmit={handleSubmit}>
							<input
								id="sign-up"
								type="email"
								name="email"
								placeholder="email@address.com"
								className="regular-14"
							/>
							<input type="hidden" name="tag" value="placeholder-signup" />
							<input type="hidden" value="1" name="embed" />

							<label htmlFor="sign-up" class="sr-only">
								Email Address
							</label>
							<button className="regular-14">SIGN UP</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
