/*
  Utility functions, any generic function that accomplishes an abstract task.
*/
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

export const printMonthYear = (dateString) => {
	const date = new Date(dateString);
	const dateArray = date.toString().split(' ');
	return `${dateArray[1]} ${dateArray[3]}`;
};

export const getUniqueValues = (data, type) => {
	let unique = data.map((item) => item[type]);
	if (type === 'date') {
		unique = data.map((item) => item[type].split('-')[0]);
	}
	if (type === 'authors') {
		unique = data.map((item) => {
			return item?.authors?.map(
				(subItem) => `${subItem?.fields?.name} ${subItem?.fields?.lastName}`
			);
		});
		unique = unique.flat();
	}
	if (type === 'publicationType') {
		unique = data.map((item) => {
			const targetString = item.publicationType;
			const arr = targetString.split(' ');

			for (let i = 0; i < arr.length; i++) {
				arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
			}
			const str2 = arr.join(' ');
			return str2;
		});
	}
	return [
		...new Set(
			unique.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'accent' }))
		),
	];
};

export const dropdownRelatedData = (uniqueItems) => {
	const tempData = [];
	uniqueItems.reduce(
		(acc, curr) => {
			acc.value = curr;
			acc.label = curr;
			tempData.push({ value: acc.value, label: acc.label });

			return acc;
		},
		{ value: '', label: '' }
	);

	return tempData;
};

export const options = {
	renderMark: {
		[MARKS.BOLD]: (text) => <strong>{text}</strong>,
		[MARKS.ITALIC]: (text) => <em>{text}</em>,
		[MARKS.UNDERLINE]: (text) => <u>{text}</u>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => (
			<div className="paragraph">{children}</div>
		),
		[BLOCKS.UL_LIST]: (node, children) => (
			<ul className="contentful-ul">{children}</ul>
		),
		[BLOCKS.OL_LIST]: (node, children) => (
			<ol className="contentful-ol">{children}</ol>
		),
		[BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
		[INLINES.HYPERLINK]: (node, children) => {
			const uri = node.data.uri;
			return (
				<a href={uri} target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			);
		},
	},
};

export const groups = [
	{ id: 'directors', name: 'Directors' },
	{ id: 'staff', name: 'Staff' },
	{ id: 'postdoctoral-fellows', name: 'Postdoctoral Fellows' },
	{ id: 'students', name: 'Students' },
	{ id: 'faculty-affiliates', name: 'Faculty Affiliates' },
	{ id: 'community-partners', name: 'Community Partners' },
	{ id: 'collaborators', name: 'Collaborators' },
];

export const sortDataByDate = (data, order) => {
	return data?.sort((a, b) => {
		const dateA = new Date(a.startDate);
		const dateB = new Date(b.startDate);
		return order === 'newest' ? dateB - dateA : dateA - dateB;
	});
};

export const getCategoryStyles = (category) => {
	return category?.map((item) => {
		switch (item) {
			case 'Presentations':
				return 'text-[#513284] bg-[#EAE6F3]';
			case 'Announcement':
				return 'text-blue-700 bg-blue-100';
			case 'Event':
				return 'text-[#394A93] bg-[#E9EEF2]';
			case 'Hiring':
				return 'text-[#694213] bg-[#F7ECDD]';
			case 'In the media':
				return 'text-[#224E57] bg-[#E9EFEB]';
			default:
				return 'text-gray-700 bg-neutral-400';
		}
	});
};

export const hackathonFaq = [
	{
		question: 'What is Digital Society Hack?',
		answer:
			'Digital Society Hack is the Digital Society Lab’s very own hackathon event that encourages participants to come up with innovative, creative, and impactful ideas that could benefit society. This one week long event includes workshops, mentors, and prizes for winners!',
	},
	{
		question: 'Who can participate?',
		answer:
			'All McMaster students, postdoctoral fellows, faculty, and alumni are eligible to apply to participate this event.',
	},
	{
		question: 'Do I need a team to participate?',
		answer:
			'You are welcome to enter with a team or as an individual. Please note that if you wish to be in a team, teammates should be found prior to applying as we will not allocate you into teams.',
	},
	{
		question: 'What if I don’t know how to code?',
		answer:
			'This is a code optional event. Those who know how to code are welcome to, but it is not a requirement for submission. Judging will be conducted based on your project’s concept and how well thought out it is.',
	},
	{
		question: 'What am I allowed to hack?',
		answer:
			'An open-ended prompt will be given at the start of the hackathon. As a general guide, the theme of our hackathon pertains to social innovation and benefitting society.',
	},
	{
		question: 'How much does it cost to participate?',
		answer: 'Participating in Digital Society Hack is completely free!',
	},
];

export const hackathonFaqContinue = [
	{
		question: 'What is the Digital Society Lab?',
		answer:
			'The Digital Society Lab is an interdisciplinary research and training centre at McMaster University that engages with the complex societal transformations that define the digital age. The Lab studies the dynamic relationship between society and digital technology and explores the potential of emerging technologies for scientific and social innovation. ',
	},
	{
		question: 'What do I need to participate?',
		answer:
			'No specialty hardware or knowledge is required. The only things needed to participate are a laptop and stable internet connection.',
	},
	{
		question: 'How big can a team be?',
		answer: 'A team can have up to 4 members.',
	},
	{
		question: 'What if I’ve never been to a hackathon before?',
		answer:
			'Absolutely no experience is required to participate in this event. We welcome participants of all skill levels and provide workshop events for those wanting to learn or refine their skills.',
	},
	{
		question: 'Where will the event take place?',
		answer:
			'This is a hybrid event that will take place on McMaster University’s campus. It will be primarily online with optional in-person components. Those who wish to attend completely virtually, may do so as well.',
	},
];
