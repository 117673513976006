import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from '../../appContext';
import Banner from '../../components/Banner/Banner';
import Searchbar from './Searchbar';
import { PUBLICATIONS } from '../../appConstants';
import PublicationCard from './PublicationCard';
import LoadingState from '../../components/LoadingState/LoadingState';
import { useGlobalFilterContext } from '../../filterContext';

const Publications = () => {
	const location = useLocation();

	useEffect(() => {
		const hash = location.hash.replace('#', '');
		if (hash) {
			const element = document.getElementById(hash);
			element && element.scrollIntoView({ behavior: 'smooth' });
		}
	}, [location]);

	const { closeSubmenu, filteredPublications: response } =
		useGlobalFilterContext();

	const { publicationsBody, publicationsTitle, setQuery, isLoading } =
		useGlobalContext();

	useEffect(() => {
		window.addEventListener('beforeunload', setQuery(PUBLICATIONS));
		return () => {
			window.removeEventListener('beforeunload', setQuery(PUBLICATIONS));
		};
	}, [setQuery]);

	if (isLoading) {
		return <LoadingState />;
	}
	if (!response) {
		return null;
	}

	let lastYear = null;

	return (
		<main className="publication-main">
			<Banner title={publicationsTitle} info={publicationsBody} />

			<div className="wrapper pub-wrapper">
				<Searchbar />
				{response.map((publication, id) => {
					const year = publication.date?.substring(0, 4);
					const showYear = year !== lastYear;
					lastYear = year;
					return (
						<div className="single-content" key={id} onClick={closeSubmenu}>
							{showYear && year && <h2 className="year">{year}</h2>}
							<PublicationCard key={id} publication={publication} id={id} />
						</div>
					);
				})}
			</div>
		</main>
	);
};

export default Publications;
