import { Select } from '@headlessui/react';

const SelectLayout = ({
	setSortOrder,
}) => {
	return (
		<Select
			name="status"
			aria-label="Project status"
			onChange={(e) => setSortOrder(e.target.value)}
			className="regular-caps sortBx bx h-[46px]">
			<option defaultValue="" disabled selected>Sort by</option>
			<option value="newest">Newest</option>
			<option value="oldest">Oldest</option>
		</Select>
	);
};

export default SelectLayout;
