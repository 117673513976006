import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGlobalContext } from '../../appContext';
import { STATIC_QUERY } from '../../appConstants';
import LogoIcon from '../../assets/logo_dark_horizontal.svg';
import LogoIconLight from '../../assets/logo_dark_horizontal_light.svg';



const Logo = ({light}) => {
    const { setQuery, setOpenMenu } = useGlobalContext();
    return (
        <section className='logo'>
            {/* LOGO */}
            <NavLink
                to='/'
                onClick={() => {
                    setQuery(STATIC_QUERY);
                    setOpenMenu(false);
                }}
                className=''
            >
            
                <img src={light ? LogoIconLight : LogoIcon} alt='DSL Logo' className='home-logo' />
            </NavLink>
        </section>
    );
};

export default Logo;
