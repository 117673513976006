import React from 'react';
import { useGlobalContext } from '../../appContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
import { Link } from 'react-router-dom';

const RightPane = () => {
	const { authorProjects, authorPublications } = useGlobalContext();

	return (
		<aside className="right-panes">
			{authorProjects && authorProjects.length > 0 && (
				<>
					<div className="projects-container container">
						<h3 className="bold-16 heading">affiliated projects</h3>
						<ul className="project-list list">
							{authorProjects?.map((project, id) => {
								const { subtitle, slug: projectSlug, title } = project;
								return (
									<li key={id} className="list-item not-list">
										<Link
											to={`/projects/${projectSlug}`}
											className="medium-16 project no-decoration">
											{subtitle ? `${title}: ${subtitle}` : title}
										</Link>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="underline" />
				</>
			)}
			{authorPublications && authorPublications.length > 0 && (
				<div className="publications-container container">
					<h3 className="bold-16 heading">publications </h3>
					<ul className="publication-list list">
						{authorPublications.map((article, id) => {
							const title = article?.title || null;
							return (
								<li key={id} className="list-item">
									<Link
										to={`/publications/#${article.id}`}
										className="medium-16 project no-decoration">
										{title && (
											<div className="publication-container">
												{documentToReactComponents(title, options)}
											</div>
										)}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</aside>
	);
};

export default RightPane;
