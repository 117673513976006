import { FaFacebookF, FaXTwitter } from 'react-icons/fa6';


function Footer({ homeStatic }) {
    return (
        <footer className='footer'>
            <div className='footer-wrapper'>
                <div className='logo-media'>
                    <div className='media-container'>
                        <a
                            href='https://twitter.com/DigSocietyLab'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Follow us on X'
                        >
                            <FaXTwitter aria-hidden='true' />
                            <span className='sr-only'>
                                Follow us on X
                            </span>
                        </a>
                        <a
                            href='https://www.facebook.com/DigSocietyLab'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Follow us on Facebook'
                        >
                            <FaFacebookF aria-hidden='true' />
                            <span className='sr-only'>
                                Follow us on Facebook
                            </span>
                        </a>
                    </div>
                </div>
                <div className='copyright-terms'>
                    <p className='copyright'>
                        &copy;&nbsp;
                        {`${new Date().getFullYear()} Digital Society Lab`}
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
