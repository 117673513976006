import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
const Banner = ({ title, info }) => {
	return (
		<section className="banner">
			<div className="wrapper">
				<div className="banner-container">
					<h1 className="banner-heading">{title}</h1>
					<div className="banner-content medium-16">
						{documentToReactComponents(info, options)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
