import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from '@headlessui/react';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';

const AccordionLayout = ({ faq }) => {
	return (
		<Disclosure>
			{({ open }) => (
				<div className="py-[30px] w-full border-b border-neutral-100">
					<DisclosureButton className="w-full flex justify-between items-center gap-2">
						<p className="text-[18px] md:text-[18px] font-semibold text-left">
							{faq?.question}
						</p>

						{open ? (
							<MinusIcon className="w-[30px]" />
						) : (
							<PlusIcon className="w-[30px]" />
						)}
					</DisclosureButton>
					<DisclosurePanel>
						<p className="text-[18px] md:text-[18px] leading-[28px] pt-[30px]">{faq?.answer}</p>
					</DisclosurePanel>
				</div>
			)}
		</Disclosure>
	);
};

export default AccordionLayout;
