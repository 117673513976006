import React from 'react';
import { Link } from 'react-router-dom';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const ProjectCard = ({ projectCardInfo }) => {
    const {
        title,
        subtitle,
        about,
        slug,
        tags,
    } = projectCardInfo;

    const truncateByWords = (text, maxWords) => {
		const words = text.split(' ');

		if (words.length > maxWords) {
			return words.slice(0, maxWords).join(' ') + '...';
		}
		return text;
	};

    return (
        <Link className='project-link' to={`/projects/${slug}`} aria-label={`Read more about ${slug}`}>
            <article className='project-card'>
                <h2 className='project-title'>{title}</h2>
                <h3 className='project-subtitle'>{subtitle}</h3>
                <p className='regular-14'>
                  {about && `${truncateByWords(documentToPlainTextString(about), 20)}`}
                </p>
                
           
                <p className='regular-16 project-card-intro'>
                    Click To Read More...
                </p>

                <div className='project-card-metadata'>
                    {/* rendering tags */}
                    {tags && (
                        <ul className='categories'>
                            {tags.map((tag, id) => {
                                return (
                                    <li className='semi-14 category' key={id}>
                                        {tag}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </article>
        </Link>
    );
};

export default ProjectCard;
