import React, { useEffect } from "react";
import { useGlobalContext } from "../../appContext";
import Banner from "../../components/Banner/Banner";
import Person from "./Person";
import { PEOPLE } from "../../appConstants";
import { groups } from "../../utils";
import LoadingState from "../../components/LoadingState/LoadingState";
const People = () => {
	const {
		peopleBody,
		peopleTitle,
		setQuery,
		isLoading,
		peopleData: response,
	} = useGlobalContext();

	useEffect(() => {
		// refresh button functionality
		window.addEventListener("beforeunload", setQuery(PEOPLE));
		return () => {
			window.removeEventListener("beforeunload", setQuery(PEOPLE));
		};
	}, [setQuery]);

	if (isLoading) {
		return <LoadingState />;
	}
	if (!response) {
		return null;
	}

	return (
		<main className="people-main">
			{/* banner */}
			<Banner title={peopleTitle} info={peopleBody} />

			<section className="people-list">
			{groups.map((item) => {
				const peopleInGroup = response.filter(
					(person) => person?.group === item.id).sort((a, b) => a.lastName.localeCompare(b.lastName, 'en', { sensitivity: 'accent' }))

				if (!peopleInGroup.length) return null;

				return (
					<div className="wrapper group" key={item.id} id={item.id}>
						<h2 className="category-title">{item.name}</h2>
						<ul className={`${item.id} contributor`}>
							{peopleInGroup.map((person, id) => {
								const memberInfo = {
									...person,
									memberType: item.id,
								};
								return <Person key={id} memberInfo={memberInfo} />;
							})}
						</ul>
					</div>
				);
			})}
			</section>
		</main>
	);
};

export default People;
