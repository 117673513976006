import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { TbExternalLink as ExternalLink } from 'react-icons/tb';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../../utils';
// import { CgLink as LinkIcon } from 'react-icons/cg';

const PublicationCard = ({ publication }) => {
	const { publicationType, title, authors, projects, url, id } = publication;
	return (
		<section className="single-publication" id={id}>
			{/* rendering publication  */}
			<div className="publication">
				<div className="meta-container">
					{(publicationType !== undefined || publicationType !== '') && (
						<div className="meta">
							{/* publication type */}
							{publicationType !== undefined && (
								<p
									className={`${
										publicationType === 'journal article'
											? 'publication-type journal bold-caps'
											: 'publication-type popular bold-caps'
									}`}>
									{publicationType}
								</p>
							)}
						</div>
					)}

					{/* journal heading */}
					<div className="journal-heading-container">
						<div className="journal-heading semi-18 ">
							{documentToReactComponents(title, options)}
							{/* if url for article is present only then render */}
							{url && (
								<div className="link-container">
									<a
										href={url}
										className="heading-url regular-caps"
										target="_blank"
										rel="noreferrer">
										Link
										<span className="regular-caps svg-container">
											<ExternalLink />
										</span>
									</a>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* rendering authors */}
			{authors !== undefined && authors.length > 0 && (
				<div className="authors-container">
					<div className="authors">
						<h4 className="regular-16 auth-heading">
							lab author{authors.length > 1 && 's'}
						</h4>
						{authors && (
							<ul className="authors-list">
								{/* if length of author is > 1, then each array item concat with comma */}
								{authors.map((author, id) => {
									const { slug, name, lastName } = author.fields;

									return (
										<Fragment key={id}>
											<li key={id} className="authors-list-item">
												<Link
													to={`/people/staff/${slug}`}
													className="author-link medium-16">
													{name} {lastName || ''}
												</Link>
											</li>{''}
											{id < authors.length - 1 && <span>,&nbsp;</span>}
										</Fragment>
									);
								})}
							</ul>
						)}
					</div>
				</div>
			)}

			{/* rendering featured projects */}

			{projects && projects.length > 0 && (
				<div className="featured-projects-container">
					<div className="featured-projects">
						<h4 className="regular-16 featured-projects-heading">
							Lab Project{projects.length > 1 && 's'}
						</h4>

						{/* projects list */}
						<ol className="project-list">
							{projects.map((project, id) => {
								const { title, slug } = project.fields;
								return (
									<div key={id}>
										<li className="project medium-16">
											<Link
												to={`/projects/${slug}`}
												className="proj-link"
												title={title}>
												{title}
											</Link>
										</li>
									</div>
								);
							})}
						</ol>
					</div>
				</div>
			)}
		</section>
	);
};

export default PublicationCard;
