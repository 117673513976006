import {
	Combobox,
	ComboboxButton,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useState } from 'react';
import downArrowIcon from '../../assets/downArrow.svg';

const ComboboxLayout = ({
	data,
	selectedCategories,
	setSelectedCategories,
}) => {
	const categoryNames = [
		...new Set(
			data.reduce((acc, item) => {
				acc.push(...item.category);
				return acc;
			}, [])
		),
	].sort((a, b) => a.localeCompare(b));

	const [query, setQuery] = useState('');

	const filteredCategories =
		query === ''
			? categoryNames
			: categoryNames.filter((category) => {
					return category.toLowerCase().includes(query.toLowerCase());
			  });

	return (
		<div className="w-52 h-full">
			<Combobox
				multiple
				value={selectedCategories}
				onChange={setSelectedCategories}
				onClose={() => setQuery('')}>
				<div className="relative">
					<ComboboxInput
						className="w-full border border-solid	border-[#dad7c9] rounded-[5px] bg-white px-[0.6rem] py-[0.7rem] text-neutral-500 regular-caps"
						displayValue={(category) => category?.name}
						aria-label="Filter"
						placeholder="FILTER"
						onChange={(event) => setQuery(event.target.value)}
					/>
					<ComboboxButton className="absolute inset-y-0 right-0 px-2.5 pb-0 flex justify-center items-center">
						<img
							src={downArrowIcon}
							alt=""
							width="12px"
							height="12px"
							style={{ marginLeft: 'auto' }}
						/>
					</ComboboxButton>
				</div>

				<ComboboxOptions
					anchor={{ to: 'start', offset: '55px' }}
					transition
					className={clsx(
						'w-[var(--input-width)] rounded-[5px] border border-solid	border-[#dad7c9] bg-white py-[1rem] px-[0.6rem] [--anchor-gap:var(--spacing-1)] empty:invisible',
						'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
					)}>
					{filteredCategories?.map((category, index) => (
						<ComboboxOption
							key={index}
							value={category}
							className="group flex cursor-default justify-start items-center gap-2 py-1.5 px-3 select-none data-[focus]:bg-soft-black/10">
							<CheckIcon className="invisible size-4 fill-soft-black group-data-[selected]:visible" />
							<div className="regular-caps text-soft-black">{category}</div>
						</ComboboxOption>
					))}
				</ComboboxOptions>
			</Combobox>
			{selectedCategories?.length > 0 && (
				<ul className="mt-[1rem] flex gap-2 flex-wrap">
					{selectedCategories?.map((category, index) => (
						<li key={category} className="semi-14">
							{category}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default ComboboxLayout;
