import {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import { useGlobalContext } from './appContext';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const { publicationsData } = useGlobalContext();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [publications, setPublications] = useState([]);
    const [filteredPublications, setFilteredPublications] = useState([]);
    // const [filteredPublications, setFilteredPublications] =
    //     useState(publicationsData);
    const [sort, setSort] = useState('');
    const [ctr, setCtr] = useState(0);
    const [filters, setFilters] = useState({
        pubType: '',
        authors: '',
        year: '',
        text: '',
    });


    const openSubmenu = () => {
        setIsSubmenuOpen(true);
    };

    const closeSubmenu = () => {
        setIsSubmenuOpen(false);
    };

    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const loadPublications = useCallback(() => {
        setPublications([...publicationsData]);
        setFilteredPublications([...publicationsData]);
    }, [publicationsData]);

    const updateSort = (e) => {
        const value = e.target.value;
        setSort(value);
    };

    const sortPublications = useCallback(() => {
        let tempPublications = [...filteredPublications];

        if (sort === 'yearL') {
            tempPublications = tempPublications.sort((a, b) => {
                const aDate = parseInt(a.date.split('-')[0]);
                const bDate = parseInt(b.date.split('-')[0]);

                return aDate - bDate;
            });
        }
        if (sort === 'yearH') {
            tempPublications = tempPublications.sort((a, b) => {
                const aDate = parseInt(a.date.split('-')[0]);
                const bDate = parseInt(b.date.split('-')[0]);

                return bDate - aDate;
            });
        }
        setFilteredPublications(tempPublications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publications, sort]);

    const updateFilters = (e) => {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;
        if (!value) {
            value = '';
        }
        const filterPreviouslyEmpty = !filters[name];
        const newFilterValueEmpty = !value;   

        if (filterPreviouslyEmpty && !newFilterValueEmpty) {
            setCtr(prevCtr => prevCtr + 1);
        } else if (!filterPreviouslyEmpty && newFilterValueEmpty) {
            setCtr(prevCtr => prevCtr - 1);
        }

        setFilters({ ...filters, [name]: value });
    };

    const clearFilters = () => {
        setCtr(0);
        setFilters({ authors: '', pubType: '', year: '', text: '' });
    };

    const filterPub = useCallback(() => {
        // (1) get all the publications from state
        // const allPublications = publications;
        const allPublications = publications;

        // (2) get all the filters from state
        const { authors, pubType, year, text } = filters;
        

        //(3) make a fresh copy of all the publications before filtering
        let tempPublications = [...allPublications];

        // (4) start filtering
        if (text) {
            tempPublications = tempPublications?.filter((pub) => {
                return (
                    pub?.searchTitle?.toLowerCase().includes(text.toLowerCase()) ||
                    pub?.publicationType?.toLowerCase().includes(text.toLowerCase()) ||
                    pub?.date?.includes(text.toLowerCase())
                );
            });
        }

        // filtering pub type dropdown
        if (pubType !== '') {
            tempPublications = tempPublications.filter((pub) => {
                return (
                    pub?.publicationType?.toLowerCase() === pubType.toLowerCase()
                );
            });
        }

        // filtering year type dropdown
        if (year !== '') {
            tempPublications = tempPublications.filter((pub) => {
                return pub?.date?.split('-')[0] === year;
            });
        }

        // authors dropdown
        if (authors !== '') {
            tempPublications = tempPublications.filter((pub) => {
                return pub?.authors?.map((p) => `${p.fields.name} ${p.fields.lastName}`).includes(authors);
            });
        }

        // ** update the filtered publication state
        setFilteredPublications(tempPublications);
    }, [filters, publications]);

    useEffect(() => {
        loadPublications();
    }, [publicationsData, loadPublications]);

    useEffect(() => {
        filterPub();
        setSort('');
    }, [filters, filterPub]);

    useEffect(() => {
        sortPublications();
    }, [sort, sortPublications]);


    return (
        <FilterContext.Provider
            value={{
                isSubmenuOpen,
                openSubmenu,
                closeSubmenu,
                toggleSubmenu,
                filters,
                updateFilters,
                clearFilters,
                publications,
                filteredPublications,
                sort,
                updateSort,
                ctr,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export const useGlobalFilterContext = () => {
    return useContext(FilterContext);
};
